.bodySpacer {
    margin-top: 15px;
    min-height: 40em;
}

/* Variables to customize sign in / sign up pages */
:root {
  --amplify-font-family: var(--bs-body-font-family);
  --amplify-primary-color: var(--bs-primary);
  --amplify-primary-tint: #5973ff;
  --amplify-primary-shade: #3e57e0;
}

.container-fluid {
    max-width: 1000px;
    padding: 0px 20px;
}

.table-responsive {
    overflow-x: visible !important;
}

a,
a:not([href]):not([class]),
.btn-link {
    color: #3e57e0;
    text-decoration: none;
}

a:hover,
a:not([href]):not([class]):hover,
.btn-link:hover {
    color: #5973ff;
}

.btn-link {
    outline: none !important;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}
